<template>
    <div class="p-3">
        <h5>免责声名</h5>
        <p>
            97打工网（以下简称“本网站”）本网站是蓝领招聘领域的移动互联网 O2O 平台，隶属于一二一通信(苏州)有限公司，是为广大求职者提供免费、可靠的求职专属经纪人服务，彻底的解决求职者在进厂工作时可能会遭遇到的困难与风险。同时，也为各大企业提供智能专属的基层人力资源招工解决方案。本声明为本网站的使用条款，凡浏览本网站或相关网页的用户，均表示接受以下条款。本网站保留对网页包含的信息和资料及显示的条款、条件和说明变更的权利。变更自公布时起生效，凡继续使用本网站的，视为接受这些更改，内容按更改后的内容执行。
            对于本网站提供的服务及服务中涉及的技术、资料和信息，可以合法的目的使用。任何单位或个人不得利用本网站从事违法或损害他人利益的行为。
            您通过其他网站链接进入本网站可能存在登录其他假冒网站的风险，建议您采用安全的方式（如在地址栏中直接键入）访问本网站.
        </p>
        <h5>知识产权声明</h5>

        <p>一、本网站涉及的任何资料（例如文字、图片、标识、域名、软件、音视频资料、版面设计等）的相关知识产权（包括但不限于著作权、商标权、域名权、商业秘密权等）均属本网站或相关权利人所有。</p>

        <p>二、对于著作权属于本网站的作品，如需引用、摘录、转载或以其它方式使用，必须取得著作权人的书面许可，摘录或引述应确保反映原文的真实含义，并在使用时注明来源（）和著作权系本网站所有的标记。对于任何违反国家有关法律法规，不遵守本网站声明，不经本网站同意，擅自使用本网站内容并不注明出处的行为，本网站保留采取法律措施追究其责任的权利。</p>
        <p>
            三、属于本网站特有的，用以区别于其他同类服务机构的文字、图形、字母、数字、三维标志和颜色的组合及上述诸要素的组合，均属于本单位的商标、标识，受法律保护。未经许可不得修改、复制或以其他方式使用上述商标、标识。任何试图淡化或丑化上述商标、标识的行为都是违法行为，我方保留追究其法律责任的权利。
            转载声明
            如需转载本网站发布的信息和服务内容，请与本网站或本网站所注明信息来源单位直接联系。未经同意，任何单位和个人引用、复制、转载、摘编等各种方式使用本网站的信息，由此造成的后果，本网站概不负责。
        </p>
        <h5>隐私保密声明</h5>
        <p>一、为了更好的服务于用户，在取得用户同意的前提下，会收集一些用户的信息。本网站承诺会依据国家法律法规的规定，采取措施保护客户的信息安全，在未取得客户同意的前提下，不得泄露或授权第三人使用。</p>
        <p>二、在符合法律规定的前提下，有关部门依照法定的程序要求我方提供客户的信息时，本网站依据其要求提供客户的信息，本网站均应免责。</p>
        <p>三、由于用户将账号透露给他人使用，或用户个人计算机遭受黑客攻击、病毒侵入或其他不可抗力的因素，导致用户信息泄露、丢失、被盗用或篡改的，我方不承担法律责任。</p>
        <h5>链接政策声明</h5>
        <p>一、某些情况下，本网站会提供跳转至其他网站或页面的链接。该链接将会引导用户至第三方发行或运营的网站，该网站并非我方的合作机构，与我方没有任何关系。我方将该链接列入网站内，仅为协助用户浏览和参考使用，相关内容请用户自行判断，风险自行承担。</p>
        <p>二、我方致力于提供良好的网站和资料，供用户考虑。对与我方有合作关系的第三方网站，我方会做出明确声明。但该声明并不视为我方同意、推荐、认可或保证该网站上提供的任何商品或服务，亦不视为我方与该网站存在任何形式的合作。</p>
        <p>三、如果链接的网站有关于软件的下载，则此链接仅为方便用户的使用。若用户在下载的过程中遇到任何困难或受到任何影响，我方概不负责。在互联网上下载软件的行为可能会受到相关知识产权法律法规的管制，用户若违反著作权使用条款的规定，一切责任自行承担。</p>
        <p>四、未经我方同意，任何第三方不得与本网站建立链接。我方有权决定是否建立此链接。依此建立的链接，并不构成我方与该网站存在任何合作关系，也不视为我方对该网站的认可。除以特定目的（如提供各种搜索服务）使用本网站链接外，任何将使用者导向本网站的链接，均需直接链接至本网站。用户基于对该链接的信任而产生的交易，风险由用户自行承担，我方不承担任何责任。</p>
        <h5>服务免责声明</h5>
        <p>一、我方网站的任何信息，不构成今后合同交易要约的一部分，我方不对因本网站全部或部分内容产生的损失或信赖本网站全部或部分内容产生的损失，负任何责任。</p>
        <p>二、互联网数据传输可能会出现异常、错误等现象，对于非我方能控制的因素造成的损失，我方概不负责。</p>
        <p>三、如第三方网站通知我方存在侵权信息、资料，我方在接到通知后会及时修改、删除。</p>
    </div>
</template>
<script>
const data = ""
</script>
